<template>
  <div class="dashboard container content">
    <div class="headline"><h1></h1></div>

    <b-modal v-model="dialogIsVisible"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
        <template>
            <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ $t("message.saldon-tilaus.confirm-order.header") }}
                </p>
                <button
                    type="button"
                    class="delete"
                    @click="confirmDialogClose"
                />
            </header>
            <section class="modal-card-body">
                <div><p>{{ $t("message.saldon-tilaus.confirm-order.txt") }}</p></div>
                <p>&nbsp;</p>
                <b-button
                    :loading="false"
                  :label="$t('message.register-company.add.send')"
                  size="is-large"
                  class="fullwidth"
                  type="is-success" 
                  v-on:click="send_order" />
            </section>
        </div>
        </template>
    </b-modal>

     <div class="tile is-parent">

        <article class="tile is-child box">
          <div class="columns">
            <div class="column is-full">
              <p class="title">{{ $t(headerMsg) }}</p>

              <section v-if="user.type == 'admin'">
                <b-field :label="$t('message.users.add.company')">
                  <b-autocomplete
                      @typing="(txt) => updateSuggestedCompanies(txt)"
                      :keep-first="false"
                      :open-on-focus="false"
                      :data="suggest_company"
                      field="name"

                      :placeholder="$t('message.users.add.company-suggest')" 
                      @select="option => company = option" />
                </b-field>
              </section>

              <section v-if="user.type == 'admin'">
                <b-field :label="$t('message.dashboard.list.duration')">
                  <b-input v-model="duration" />
                </b-field>
              </section>
             
              <section>
                  <p v-html="$t('message.saldon-tilaus.txt')"></p>
                  <b-field :label="$t('message.saldon-tilaus.add.date-valid')">
                  <b-datepicker
                    ref="datepicker"
                    :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                    :day-names="['ma','ti','ke','to','pe','la','su']"
                    :date-formatter="_formatDate"
                    v-model="validity_beginning">
                  </b-datepicker>
                </b-field>
                <b-field :label="$t('message.dashboard.list.duration')">
                    <b-select v-mode="duration">
                        <option value="3">3kk</option>
                        <option value="6">6kk</option>
                        <option value="9">9kk</option>
                        <option value="12">12kk</option>
                    </b-select>
                </b-field>
              </section>

              <p>&nbsp;</p>

              <b-table :data="order">
                <b-table-column field="email" label="Email" width="250" sortable v-slot="props">
                    <b-autocomplete
                      @typing="(txt) => {
                        update_model_2(txt, 'email', props.row.idx)
                        updateSuggestions(props.row.idx)
                      }"
                      @select="option => {
                        if (option && option != null) {
                          update_model_2(option.email, 'email', props.row.idx)
                        }
                      }"
                      :keep-first="false"
                      :open-on-focus="false"
                      :data="suggest"
                      field="email"

                      :placeholder="$t('message.saldon-tilaus.placeholder.email')" 
                      :value="props.row.email" />
                </b-table-column>

                <b-table-column field="amount" label="Määrä (€)" width="40" numeric sortable v-slot="props">
                    <b-input @change.native="(evt) => update_model(evt, 'amount', props.row.idx)"  :value="props.row.amount" />
                </b-table-column>

                <b-table-column field="validity_beginning" label="Poikkeava voimaantulopäivä" width="40" v-slot="props">
                      <b-datepicker
                    ref="datepicker"
                    :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                    :day-names="['ma','ti','ke','to','pe','la','su']"
                    :date-formatter="_formatDate"
                    v-model="props.row.validity_beginning">
                  </b-datepicker>
                </b-table-column>

              </b-table>
              
              <b-button
                  :loading="false"
                :label="$t('message.saldon-tilaus.add.row')"
                size="is-large"
                class="fullwidth"
                type="is-success" 
                style="background-color:#ccc"
                v-on:click="add_row" />

            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
            </div>
            <div class="column is-half">
              <p>
                <b-button
                    :loading="false"
                  :label="$t('message.register-company.add.send')"
                  size="is-large"
                  class="fullwidth"
                  type="is-success" 
                  v-on:click="confirmDialogOpen" />
              </p>
            </div>
          </div>
        </article>

     </div>
  </div>
</template>

<script>
export default {
    props: {
      saldoType: {
        type: String,
        default: 'muutosturva',
      },
      headerMsg: {
        type: String, 
        default: 'message.order.muutosturva.header'
      }
    },
    data() {
        return {
            company: null,
            suggest_company: [],
            dialogIsVisible: false,
            suggest: [],
            validity_beginning: this.$moment()._d,
            duration: 6,
            orderTemplate: {
                idx: 0,
                employee_id: -1,
                firstname: '',
                lastname: '',
                mobile: '',
                email: '',
                validity_beginning: null,
                amount: 100, 
            },
            order: []
        }
    },

    computed: {
        employees() {
            return this.$store.getters['getEmployees']
        },
        user() {
            return this.$store.getters['getUser']
        },
        companies() {
            return this.$store.getters['getCompanies']
        }, 
    },

    methods: {
        updateSuggestedCompanies(txt) {
            var ff = this.companies.filter(comp => {
                return (
                    comp.name
                        .toString()
                        .toLowerCase()
                        .indexOf(txt.toLowerCase()) >= 0
                )
            })
            this.suggest_company = ff
        },

        confirmDialogOpen() {
            this.dialogIsVisible = true
        },

        confirmDialogClose() {
            this.dialogIsVisible = false
        },
        updateSuggestions(idx) {
            var oo = this.order.find(o => o.idx == idx)
            var ff = this.employees.filter(empl => {
                return (
                    empl.email
                        .toString()
                        .toLowerCase()
                        .indexOf(oo.email.toLowerCase()) >= 0
                )
            })
            var emailsInOrder = this.order.map(order => order.email)
            ff = ff.filter(empl => {
              return !emailsInOrder.includes(empl.email)
            })
            this.suggest = ff
        },
        clone(obj) {
            if(obj == null || typeof(obj) != 'object')
                return obj;    
            var temp = new obj.constructor(); 
            for(var key in obj)
                temp[key] = this.clone(obj[key]);    
            return temp;
        },
        update_model(evt, field, idx) {
            var oo = this.order.find(o => o.idx == idx)
            if (oo != null) {
                oo[field] = evt.target.value
            }
        },
        update_model_2(txt, field, idx) {
            var oo = this.order.find(o => o.idx == idx)
            if (oo != null) {
                oo[field] = txt
            }
        },
        add_row() {
            var _max = -1
            this.order.forEach(o => {
              if (o.idx > _max) {
                _max = o.idx
              }
            })
            var clone = this.clone(this.orderTemplate)
            clone.idx = _max + 1 
            this.order.push(clone)
        },
        openDialog() {
          this.dialogIsVisible = true
        },
        closeDialog() {
          this.dialogIsVisible = false
        },
        send_order() {

          if (this.user.type == 'admin' && this.company == null) {
            this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.missing-company'));
            return 
          }

          // create employees not existing yet
          var existing = this.employees.map(e => e.email)

          var order2 = this.order.filter(o => {
            return o.email != null && o.email != "" 
          })

          var missing = order2.filter(o => {
            return !existing.includes(o.email)
          })
          
          if (missing.length > 0) {
            if (missing.length > 1) {
              this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.missing-many-employees'));  
            } else {
              this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.missing-employees', {email: missing[0].email}));
            }
            return 
          }

          // get employees
          this.$store.dispatch("fetchEmployees2").then((response) => {

            var employees = response.data

              // add employee id, uuid, name, lastname, mobile, language
            order2.forEach(r => {
              var emp = employees.find(e => e.email == r.email)
              r.firstname = emp.first_name
              r.lastname = emp.last_name
              r.employee_id = emp.id
              r.uuid = emp.uuid
              r.lang = emp.lang
              r.mobile = emp.mobile
              //console.log("employee", emp, "order employee", r)
            })
            
            var amount = 0
            order2.forEach(o => {
              amount += parseFloat(o.amount)
            })

            // todo: drop idx from employee
            var real_order = {
                type: this.saldoType, // tai koulutusetu
                amount: amount,
                meta: {
                  employees: order2,
                  duration: this.duration,
                },
                validity_beginning: this.$moment(this.validity_beginning).format('YYYY-MM-DD'),
              }
            
            if (this.user.type == 'admin') {
              real_order['company_id'] = this.company.id
            }

            // send order
            this.$store.dispatch("createOrder", real_order).then((response) => {
                var status = response.status
                if (status >= 200 && status < 300) { 
                    this.toastSuccess(this.$t('message.saldon-tilaus.toaster.success.order-created'))
                    this.$router.push('/saldontilaus-kiitos')
                } else {
                    this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-order'));
                }
            }).catch((error) => {
                this.ERROR(error);
                this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-order'));
            });
          });
        },
    },
    mounted() {
        this.$store.dispatch("fetchEmployees");
        this.add_row()
        this.$store.dispatch("fetchCompanies");
        console.log(this.employees);
    },
}
</script>

